'use client'

import { Box, Text, Button, Image, Flex } from '@chakra-ui/react'
import Link from 'next/link'
import { useEffect, useState } from 'react'

export default function NotFound() {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    // 3초 후에 페이지가 이동됩니다.
    setTimeout(() => {
      window.location.replace('/')
    }, 3000)
  }, [])

  if (!isMounted) {
    return null // 서버와 클라이언트 일치 문제 해결
  }

  return (
    <Flex
      justify="center"
      align="center"
      h="100vh"
      bgGradient="linear(-45deg, #fff300, #efe400)"
      direction="column">
      <Box
        id="error-text"
        textAlign="center"
        fontFamily="'Shabnam', Tahoma, sans-serif">
        {/* 이미지 */}
        <Image
          src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
          alt="404"
          margin="85px auto 20px"
          height="342px"
        />

        {/* 404 PAGE 텍스트 */}
        <Text
          as="span"
          fontSize="3.3em"
          fontWeight="900"
          mb={10}>
          404 PAGE
        </Text>

        {/* 설명 텍스트 */}
        <Text
          fontSize="2.8em"
          my={4}>
          잘못된 주소로 접속하셨습니다. 😅
        </Text>
        <Text
          fontSize="1.8em"
          mb={6}>
          이전 페이지로 돌아갈게요! 😉
        </Text>

        {/* 돌아가기 버튼 */}
        <Link
          href="/"
          passHref>
          <Button
            as="a"
            backgroundColor="#fff"
            color="#000"
            fontSize="30px"
            padding=".7em 2em"
            borderRadius="full"
            boxShadow="0 20px 70px 4px rgba(0, 0, 0, 0.1), inset 7px 33px 0 0px #fff300"
            fontWeight="900"
            transition="all 300ms ease"
            _hover={{
              transform: 'translateY(-13px)',
              boxShadow:
                '0 35px 90px 4px rgba(0, 0, 0, 0.3), inset 0px 0 0 3px #000',
            }}>
            돌아가기
          </Button>
        </Link>
      </Box>
    </Flex>
  )
}
